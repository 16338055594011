module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#091F43","theme_color":"#091F43","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d1aab65f4d1514a701947e6953b35c7b"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-125775228-1","AW-477371178"],"gtagConfig":{"respectDNT":true,"anonymize_ip":true},"pluginConfig":{"head":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.namebase.io"},
    },{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"0aBKKeesUBTum3Z2cKc3u8G27aQQJZRH","devKey":"kVj9HW0gaycep8I1gfIPsRWQ3oCWJPIl","trackPage":false,"delayLoad":false,"delayLoadTime":1000},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
